import { useEffect, useState } from "react";
import { Link, useParams, useLocation, useSearchParams } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import ModalWrapper from "../components/ModalWrapper";
import ScoreModal from "../components/ScoreModal";
import Loader from "../components/Loader";

const Game = () => {
	const [game, setGame] = useState(null)
	const [isVisibleScoreModal, setVisibleScoreModal] = useState(false)
	const [isLoading, setLoading] = useState(false)
	const [searchParams, setSearchParams] = useSearchParams();
	const userName = searchParams.get('userName');
	const userId = searchParams.get('userId');
	const { id } = useParams();
	const location = useLocation();
	
	useEffect(() => {
		if(location.state?.from === 'leaderboard') {
			setVisibleScoreModal(true)
		}
	}, [location]);
	
	useEffect(() => {
		if (window.parent) {
			window.parent.postMessage({
				navSettings: {
					show: false,
				}
			}, '*')
		}
		async function fetchGame() {
			try {
				setLoading(true)
				const response = await fetch(`${process.env.REACT_APP_API_URL}/api/games/${id}`);
				const data = await response.json();
				
				setGame(data?.data)
			} catch (error) {
				console.error('Произошла ошибка:', error);
			} finally {
				setLoading(false)
			}
		}
		
		fetchGame();
	}, []);
	
	const handleMessage = (event) => {
		if (event.data && event.data.score >= 0) {
			if (window.parent) {
				window.parent.postMessage({ gameEnded: true }, '*')
			}
			setSearchParams({
				userName,
				userId,
				currentScore: event.data.score,
			})
			setVisibleScoreModal(true);
		}
	};
	
	useEffect(() => {
		window.addEventListener('message', handleMessage);

		return () => {
			window.removeEventListener('message', handleMessage);
		};
	}, []);
	
	const handleClose = () => {
		document.body.style.overflow = '';
		setVisibleScoreModal(false)
	}
	
	return (
		<div className="game-page">
			<Link to="/" className='go-back'>
				<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M15 5L8 12L15 19" stroke="black" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round"/>
				</svg>
				
				<div className="subtitle">Back to Game center</div>
			</Link>
			
			{isLoading ? <Loader /> : <iframe
				className='game-iframe'
				src={game?.url}
				title={game?.name}
				allowFullScreen
			/>}
			
			<TransitionGroup>
				{isVisibleScoreModal && (
					<CSSTransition classNames="fade" timeout={300}>
						<ModalWrapper isOpen={isVisibleScoreModal} setIsOpen={setVisibleScoreModal}>
							<ScoreModal
								handleClose={handleClose}
								gameId={game?.id}
							/>
						</ModalWrapper>
					</CSSTransition>
				)}
			</TransitionGroup>
		</div>
	)
}

export default Game;