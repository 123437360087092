import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect } from "react";

const ScoreModal = ({ handleClose, gameId }) => {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const userName = searchParams.get('userName');
	const currentScore = searchParams.get('currentScore');
	const userId = localStorage.getItem('userId');
	
	async function sendUserName() {
		try {
			await fetch(`${process.env.REACT_APP_API_URL}/api/leaderboards`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					data: {
						game: gameId,
						user: userId,
						points: currentScore
					}
				}),
			});
		} catch (error) {
			console.error('Произошла ошибка:', error);
		}
	}
	
	useEffect(() => {
		sendUserName()
	}, [])
	
	const goToLocalLeaderboard = () => {
		document.body.style.overflow = '';
		
		const queryParams = new URLSearchParams({ gameId }).toString();
		const goBackPath = `/games/${gameId}?userName=${userName}&currentScore=${currentScore}`;
		navigate(`/local-leaderboard?${queryParams}&userName=${userName}&currentScore=${currentScore}`, { state: { from: goBackPath } });
	}
	
	const goToNationalLeaderboard = () => {
		document.body.style.overflow = '';
		
		const queryParams = new URLSearchParams({ gameId }).toString();
		const goBackPath = `/games/${gameId}?userName=${userName}&currentScore=${currentScore}`;
		navigate(`/national-leaderboard?${queryParams}&userName=${userName}&currentScore=${currentScore}`, { state: { from: goBackPath } });
	}
	
	return (
		<div className="basic-modal">
			<img className='logo' src="/images/logo.png" alt="logo"/>
			
			<div className="basic-modal__info">
				<div className="subtitle">Your score is:</div>
				<div className="title">{currentScore}</div>
			</div>
			
			<button onClick={goToLocalLeaderboard} className="btn pink" type="button">
				<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
					<g clipPath="url(#clip0_128_81144)">
						<path d="M15.828 12.1616H11.4662V8.49769C11.4662 8.20862 11.2319 7.97428 10.9428 7.97428H6.05755C5.76849 7.97428 5.53414 8.20862 5.53414 8.49769V10.0679H1.17233C0.88327 10.0679 0.648926 10.3023 0.648926 10.5913V15.4766C0.648926 15.7656 0.88327 16 1.17233 16C1.43946 16 15.5283 16 15.828 16C16.1171 16 16.3514 15.7657 16.3514 15.4766V12.685C16.3514 12.396 16.1171 12.1616 15.828 12.1616ZM5.53414 14.9532H1.69577V11.1148H5.53414V14.9532ZM10.4194 14.9532H6.58099C6.58099 14.8041 6.58099 9.18491 6.58099 9.02109H10.4194C10.4194 9.31122 10.4194 14.671 10.4194 14.9532ZM15.3046 14.9532H11.4662V13.2084H15.3046V14.9532Z" fill="white"/>
						<path d="M11.5096 2.2625L9.63486 1.99003L8.79649 0.29125C8.6048 -0.0970623 8.04943 -0.0971248 7.85777 0.29125L7.01936 1.99009L5.14458 2.26256C4.71618 2.32475 4.5443 2.85297 4.85452 3.15534L6.21108 4.47766L5.89083 6.34484C5.81771 6.77147 6.26671 7.09825 6.6503 6.89663L8.32715 6.01503L10.004 6.89663C10.386 7.09734 10.8367 6.77259 10.7634 6.34484L10.4432 4.47769L11.7997 3.15534C12.1097 2.85309 11.9384 2.32472 11.5096 2.2625ZM9.51543 3.92006C9.39205 4.04031 9.33574 4.21356 9.3649 4.38334L9.5524 5.47641L8.57074 4.96031C8.41821 4.88016 8.23602 4.88016 8.08358 4.96031L7.1019 5.47644L7.2894 4.38334C7.31852 4.21356 7.26224 4.04031 7.13886 3.92006L6.34471 3.14594L7.44224 2.98644C7.61274 2.96166 7.76005 2.85459 7.83633 2.70013L8.32715 1.70559L8.81793 2.70013C8.89418 2.85459 9.04152 2.96166 9.21202 2.98644L10.3096 3.14594L9.51543 3.92006Z" fill="white"/>
					</g>
					<defs>
						<clipPath id="clip0_128_81144">
							<rect width="16" height="16" fill="white" transform="translate(0.5)"/>
						</clipPath>
					</defs>
				</svg>
				
				Local Leaderboard
			</button>
			<button onClick={goToNationalLeaderboard} className="btn pink" type="button">
				<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
					<g clipPath="url(#clip0_128_81144)">
						<path d="M15.828 12.1616H11.4662V8.49769C11.4662 8.20862 11.2319 7.97428 10.9428 7.97428H6.05755C5.76849 7.97428 5.53414 8.20862 5.53414 8.49769V10.0679H1.17233C0.88327 10.0679 0.648926 10.3023 0.648926 10.5913V15.4766C0.648926 15.7656 0.88327 16 1.17233 16C1.43946 16 15.5283 16 15.828 16C16.1171 16 16.3514 15.7657 16.3514 15.4766V12.685C16.3514 12.396 16.1171 12.1616 15.828 12.1616ZM5.53414 14.9532H1.69577V11.1148H5.53414V14.9532ZM10.4194 14.9532H6.58099C6.58099 14.8041 6.58099 9.18491 6.58099 9.02109H10.4194C10.4194 9.31122 10.4194 14.671 10.4194 14.9532ZM15.3046 14.9532H11.4662V13.2084H15.3046V14.9532Z" fill="white"/>
						<path d="M11.5096 2.2625L9.63486 1.99003L8.79649 0.29125C8.6048 -0.0970623 8.04943 -0.0971248 7.85777 0.29125L7.01936 1.99009L5.14458 2.26256C4.71618 2.32475 4.5443 2.85297 4.85452 3.15534L6.21108 4.47766L5.89083 6.34484C5.81771 6.77147 6.26671 7.09825 6.6503 6.89663L8.32715 6.01503L10.004 6.89663C10.386 7.09734 10.8367 6.77259 10.7634 6.34484L10.4432 4.47769L11.7997 3.15534C12.1097 2.85309 11.9384 2.32472 11.5096 2.2625ZM9.51543 3.92006C9.39205 4.04031 9.33574 4.21356 9.3649 4.38334L9.5524 5.47641L8.57074 4.96031C8.41821 4.88016 8.23602 4.88016 8.08358 4.96031L7.1019 5.47644L7.2894 4.38334C7.31852 4.21356 7.26224 4.04031 7.13886 3.92006L6.34471 3.14594L7.44224 2.98644C7.61274 2.96166 7.76005 2.85459 7.83633 2.70013L8.32715 1.70559L8.81793 2.70013C8.89418 2.85459 9.04152 2.96166 9.21202 2.98644L10.3096 3.14594L9.51543 3.92006Z" fill="white"/>
					</g>
					<defs>
						<clipPath id="clip0_128_81144">
							<rect width="16" height="16" fill="white" transform="translate(0.5)"/>
						</clipPath>
					</defs>
				</svg>
				
				National Leaderboard
			</button>
			<button onClick={handleClose} className="btn black" type="button">Return to the game</button>
		</div>
	)
}

export default ScoreModal;