import { useState } from "react";
import { useNavigate } from "react-router-dom";
import classnames from "classnames";

const UserModal = ({ selectedGameId, setOnFocusInput }) => {
	const [userName, setUserName] = useState('');
	const navigate = useNavigate();
	
	async function sendUserName() {
		try {
			const response = await fetch(`${process.env.REACT_APP_API_URL}/api/users`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ username: userName }),
			});
			
			const data = await response.json();
			
			localStorage.setItem('userId', data?.id);
			localStorage.setItem('userName', userName);
			
			navigate(`/games/${selectedGameId}?userName=${userName}`);
		} catch (error) {
			console.error('Произошла ошибка:', error);
		}
	}
	
	const handleClick = () => {
		sendUserName()
	}
	
	const handleChange = (e) => {
		setUserName(e.target.value)
	}
	
	const handleFocus = () => {
		setOnFocusInput(true)
	}
	
	const handleBlur = () => {
		setOnFocusInput(false)
	}
	
	return (
		<div className="basic-modal fade-enter fade-enter-active">
			<img className='logo' src="/images/logo.png" alt="logo"/>
			<div className="subtitle">Enter your nickname</div>
			<input
				onChange={handleChange}
				onFocus={handleFocus}
				onBlur={handleBlur}
				value={userName}
				placeholder="Enter your nickname"
				type="text"
			/>
			
			<button
				className={classnames('btn pink', { 'disabled': !userName.length})}
				disabled={!userName.length}
				onClick={handleClick}
				type="button"
			>
				Continue
			</button>
		</div>
	)
}

export default UserModal;