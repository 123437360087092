import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import moment from "moment";
import Loader from "../components/Loader";

const LocalLeaderBoard = () => {
	const [leadersInfo, setLeadersInfo] = useState([])
	const [isLoading, setLoading] = useState(false)
	const navigate = useNavigate();
	const location = useLocation();
	const [searchParams] = useSearchParams();
	const gameId = searchParams.get('gameId');
	const userName = searchParams.get('userName');
	const currentScore = searchParams.get('currentScore');
	const date = moment().format('MMMM D, YYYY');
	
	useEffect(() => {
		async function fetchLeaders() {
			try {
				setLoading(true)
				const response = await fetch(`${process.env.REACT_APP_API_URL}/api/leaderboards?filters[game]=${gameId}&populate=deep,2`);
				const data = await response.json();
				
				setLeadersInfo(data)
			} catch (error) {
				console.error('Произошла ошибка:', error);
			} finally {
				setLoading(false)
			}
		}
		
		fetchLeaders();
	}, []);
	
	const handleGoBack = () => {
		navigate(location.state.from, { state: { from: 'leaderboard' } });
	};
	
	if (isLoading) return <Loader />
	
	return (
		<div className='leaderboard-page page'>
			<div className="container">
				<button type="button"  onClick={handleGoBack} className='go-back'>
					<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M15 5L8 12L15 19" stroke="black" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round"/>
					</svg>
					
					<div className="subtitle">Back to the Game</div>
				</button>
				
				<div className="preview-image" style={{ backgroundImage: `url(${process.env.REACT_APP_API_URL}${leadersInfo?.meta?.cover?.[0]?.url})` }} />
				
				<div className='description'>
					<div className='title'>Local Leaderboard</div>
					<div className='subtitle'>{date}</div>
					<div className="plus-bg" />
				</div>
				
				<div className="score-list">
					{leadersInfo?.data?.map(({ user, points }, index) => (
						<div key={index} className="score-item">
							<div className="score-item__name">{user?.username}</div>
							<div className="score-item__amount">{points}</div>
						</div>
					))}
				</div>
				
				<div className='your-result'>
					<div className='your-result__title'>Your result:</div>
					<div className="score-item">
						<div className="score-item__name">{userName}</div>
						<div className="score-item__amount">{currentScore}</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default LocalLeaderBoard;