import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { TransitionGroup } from "react-transition-group";
import LocalLeaderBoard from "./pages/LocalLeaderBoard";
import NationalLeaderBoard from "./pages/NationalLeaderBoard";
import Main from "./pages/Games";
import Game from "./pages/Game";
import './styles/index.scss';

function App() {
  
  return (
      <TransitionGroup>
          <Router>
              <Routes>
                  <Route exact path="/" element={<Main />} />
                  <Route path="/games/:id" element={<Game />} />
                  <Route path="/local-leaderboard" element={<LocalLeaderBoard />} />
                  <Route path="/national-leaderboard" element={<NationalLeaderBoard />} />
              </Routes>
          </Router>
      </TransitionGroup>
  );
}

export default App;
