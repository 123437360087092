import { useEffect, useState } from "react";
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import UserModal from "../components/UserModal";
import ModalWrapper from "../components/ModalWrapper";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
// import { __wtInitEvents } from "../script/wt-events";
import {Helmet} from 'react-helmet';

const Games = () => {
	const [games, setGames] = useState([])
	const [isLoading, setLoading] = useState(false)
	const [isVisibleUserModal, setVisibleUserModal] = useState(false)
	const [selectedGameId, setSelectedGameId] = useState(null)
	const [onFocusInput, setOnFocusInput] = useState(false)
	const navigate = useNavigate();
	const userName = localStorage.getItem('userName');
	const [rewardPoints, setRewardPoints] = useState(localStorage.getItem('rewardPoints'));
	
	
	useEffect(() => {
		async function fetchGames() {
			try {
				setLoading(true)
				const response = await fetch(`${process.env.REACT_APP_API_URL}/api/pages/1?populate=deep`);
				const data = await response.json();
				
				setGames(data?.data?.games)
			} catch (error) {
				console.error('Произошла ошибка:', error);
			} finally {
				setLoading(false)
			}
		}
		
		fetchGames();
		
		if (window.parent) {
			window.parent.postMessage({
				navSettings: {
					show: true,
				}
			}, '*')
		}
	}, []);
	
	useEffect(() => {
		const handleRewardPointsUpdate = (event) => {
			if (typeof event.data === 'object' && event.data.hasOwnProperty('rewardPoints')) {
				const newRewardPoints = event.data.rewardPoints;
				setRewardPoints(newRewardPoints);
				localStorage.setItem('rewardPoints', newRewardPoints);
			}
		};
		
		window.addEventListener('message', handleRewardPointsUpdate);
		
		return () => {
			window.removeEventListener('message', handleRewardPointsUpdate);
		};
	}, [rewardPoints]);
	
	const handleSelectedGame = (id) => {
		if (userName) {
			navigate(`/games/${id}?userName=${userName}`);
		}
		setSelectedGameId(id)
		setVisibleUserModal(true)
	}
	
	useEffect(() => {
		document.body.style.overflow = '';
	})
	
	return (
		<>
			<Helmet>
				<script async='true' src='wt-events.js?version=1.0.7' />
			</Helmet>
			<div className='games-page page'>
				<div className='container'>
					<a className='logo-link' href="/">
						<img className='logo' src="/images/logo.png" alt="logo"/>
					</a>
				
					<div className="user-info">
						<div className='user-type'>{userName ? userName : 'Guest'}</div>
						<div className='user-score'>
							<div className="user-score__label">Reward Points:</div>
							<div className='user-score__amount'>{rewardPoints ? rewardPoints : '0'}</div>
						</div>
					</div>
					
					<div className='description'>
						<div className='title'>Game Center</div>
						<div className='subtitle'>Play to be rewarded</div>
						<div className="plus-bg" />
					</div>
					
					<div className="games">
						<>
							{isLoading && <Loader />}
							{!isLoading && games.map(({ preview, id }) => (
								<div
									onClick={() => handleSelectedGame(id)}
									key={id}
									style={{ backgroundImage: `url(${process.env.REACT_APP_API_URL}${preview.url})` }}
									className="game"
								/>
							))}
						</>
					</div>
				</div>
				
				
				<TransitionGroup>
					{isVisibleUserModal && (
						<CSSTransition classNames="fade" timeout={300}>
							<ModalWrapper isOpen={isVisibleUserModal} setIsOpen={setVisibleUserModal} setPosition={setOnFocusInput} isChangePosition={onFocusInput}>
								<UserModal
									selectedGameId={selectedGameId}
									setVisibleUserModal={setVisibleUserModal}
									setOnFocusInput={setOnFocusInput}
								/>
							</ModalWrapper>
						</CSSTransition>
					)}
				</TransitionGroup>
			</div>
		</>
	)
}

export default Games;