import React, { useEffect, useRef } from 'react';
import classnames from "classnames";

function Modal({ isOpen, setIsOpen, children, isChangePosition }) {
	const modalRef = useRef(null);
	
	useEffect(() => {
		const handleOutsideClick = (event) => {
			if (modalRef.current && !modalRef.current.contains(event.target)) {
				setIsOpen(false);
			}
		};
		
		document.addEventListener('mousedown', handleOutsideClick);
		
		return () => {
			document.removeEventListener('mousedown', handleOutsideClick);
		};
	}, [setIsOpen]);
	
	useEffect(() => {
		if (isOpen) {
			document.body.style.overflow = 'hidden';
		}
	}, [isOpen]);
	
	if (!isOpen) return;
	
	return (
		<div className="modal-overlay">
			<div className={classnames('modal-content', { 'change-position': isChangePosition})} ref={modalRef}>
				{children}
			</div>
		</div>
	);
}

export default Modal;